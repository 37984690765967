body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: #364659;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}

:root{
    --bg: #06090D;
    --bg-accent: #121B26;
    --text-color: #fff;
    --nav-size: 60px;
    --border: 1px solid #364659;
    --border-radius: 8px;
}

ul {
    list-style: none;
    margin: 0;
    padding: 0;
}

a {
    color: var(--text-color);
    text-decoration: none;
}

.navbar {
    height: var(--nav-size);
    background-color: var(--bg);
    padding: 0 1rem;
    border-bottom: var(--border);
}

.navbar-nav {
    max-width: 100%;
    height: 100%;
    display: flex;
    justify-content: flex-end;
}

.nav-item {
    width: calc(var(--nav-size) * 0.9);
    display: flex;
    align-items: center;
    justify-content: center;
}

.icon-button {
    --button-size: calc(var(--nav-size) * 0.5);
    width: var(--button-size);
    height: var(--button-size);
    background-color: var(--bg-accent);
    padding: 5px;
    margin: 2px;
    display: flex;
    align-items: center;
    justify-content: center;
}
